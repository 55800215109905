<template>
    <HeaderPanel />
    <ContextMenu ref="menu" :model="menuItems" />
    <Tabs class="tabs" value="1">
        <TabList>
            <Tab value="0">СМС</Tab>
            <Tab value="1">WhatsApp</Tab>
        </TabList>
        <TabPanels>
            <TabPanel value="0">
                <div class="sms">
                    <MultiSelect v-model="sms_data.selectedPhones" :options="sms_data.selectPhones" optionLabel="name"  placeholder="База номеров" @change="selectPhonesToSrt()"/>

                <FloatLabel class="label" variant="on">
                    <Textarea id="phones" v-model="sms_data.phones" rows="7" cols="70"></Textarea>
                    <label for="phones">Список телефонов</label>
                </FloatLabel>

                <FloatLabel class="label" variant="on">
                    <Textarea id="message" v-model="sms_data.message" rows="7" cols="70"></Textarea>
                    <label for="message">Сообщение</label>
                </FloatLabel>

                <div>
                    <Checkbox v-model="sms_data.tinyurl" binary :true-value="'1'" :false-value="'0'"  />
                    <label>Сократить ссылки</label>
                </div>
                
                <ButtonVue @click="getSmsPrice()">Рассчитать</ButtonVue>
                
                <DataTable :value="price" tableStyle="min-width: 50rem">
                    <ColumnTable field="cost" header="Цена"></ColumnTable>
                        <ColumnTable field="cnt" header="Количество СМС"></ColumnTable>
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel value="1">
                <div class="whatsapp">
                    <div class="whatsapp-content">
                        <div class="header-container">
                            <p><b>Включить заголовок&nbsp;&nbsp;</b><ToggleSwitch v-model="whatsapp_data.showHeader" /></p>
                            <FloatLabel v-if="whatsapp_data.showHeader" class="message-label" variant="on">
                                <Textarea id="header" :maxlength="60" v-model="whatsapp_data.header" rows="2"></Textarea>
                                <label for="header">Текст заголовка</label>
                                <div class="counter">
                                    <span>{{ whatsapp_data.header.length }}</span> / 60
                                </div>
                            </FloatLabel>
                        </div>
                        <Divider />
                        <p><b>Основной текст</b></p>
                        <FloatLabel class="message-label" variant="on">
                            <WaEmoji class="emoji-button" @select="onEmojiSelect" />
                            <Textarea id="message" :maxlength="1024" v-model="whatsapp_data.message" autoResize rows="7" @contextmenu="onTabsRightClick"></Textarea>
                            <label for="message">Сообщение</label>
                            <div class="counter">
                                <span>{{ whatsapp_data.message.length }}</span> / 1024
                            </div>
                        </FloatLabel>
                        <Divider />
                        <div class="footer-container">
                            <p><b>Включить подпись&nbsp;&nbsp;</b><ToggleSwitch v-model="whatsapp_data.showFooter" /></p>
                            <FloatLabel v-if="whatsapp_data.showFooter" class="message-label" variant="on">
                                <Textarea id="footer" :maxlength="60" v-model="whatsapp_data.footer" rows="2"></Textarea>
                                <label for="footer">Текст подписи</label>
                                <div class="counter">
                                    <span>{{ whatsapp_data.footer.length }}</span> / 60
                                </div>
                            </FloatLabel>
                        </div>
                        <Divider />
                        <div class="button-container">
                            <p><b>Включить кнопку&nbsp;&nbsp;</b><ToggleSwitch v-model="whatsapp_data.showButton" /></p>
                            <FloatLabel v-if="whatsapp_data.showButton" variant="on">
                                <SelectVue v-model="whatsapp_data.buttonType" inputId="button-type" :options="whatsapp_data.buttonTypes" optionLabel="name"/>
                                <label for="button-type">Тип</label>
                            </FloatLabel>
                            <div v-if="whatsapp_data.showButton && whatsapp_data.buttonType.type == 'quick_reply'" class="float-buttons">
                                <div v-for="button in whatsapp_data.quickReplyButton" :key="button.id" class="float-button">
                                    <FloatLabel variant="on">
                                        <Textarea id="button" :maxlength="25" v-model="button.text" rows="2" :disabled="!button.isEnabled"></Textarea>
                                        <label for="button">Текст кнопки</label>
                                        <div class="counter">
                                            <span>{{ button.text.length }}</span> / 25
                                        </div>
                                    </FloatLabel>
                                    <ToggleSwitch v-model="button.isEnabled" />
                                </div>
                            </div>
                            <div v-if="whatsapp_data.showButton && whatsapp_data.buttonType.type == 'link_call'" class="float-buttons">
                                <div class="float-button">
                                    <FloatLabel variant="on">
                                        <Textarea id="button" :maxlength="25" v-model="whatsapp_data.linkCallButton[0].text" rows="2" :disabled="!whatsapp_data.linkCallButton[0].isEnabled"></Textarea>
                                        <label for="button">Текст кнопки</label>
                                        <div class="counter">
                                            <span>{{ whatsapp_data.linkCallButton[0].text.length }}</span> / 25
                                        </div>
                                    </FloatLabel>
                                    <FloatLabel variant="on">
                                        <Textarea id="button" v-model="whatsapp_data.linkCallButton[0].link" rows="2" :disabled="!whatsapp_data.linkCallButton[0].isEnabled"></Textarea>
                                        <label for="button">Ссылка на сайт</label>
                                    </FloatLabel>
                                    <ToggleSwitch v-model="whatsapp_data.linkCallButton[0].isEnabled" />
                                </div>
                                <div class="float-button">
                                    <FloatLabel variant="on">
                                        <Textarea id="button" :maxlength="25" v-model="whatsapp_data.linkCallButton[1].text" rows="2" :disabled="!whatsapp_data.linkCallButton[1].isEnabled"></Textarea>
                                        <label for="button">Текст кнопки</label>
                                        <div class="counter">
                                            <span>{{ whatsapp_data.linkCallButton[1].text.length }}</span> / 25
                                        </div>
                                    </FloatLabel>
                                    <FloatLabel variant="on">
                                        <Textarea id="button" v-model="whatsapp_data.linkCallButton[1].link" rows="2" :disabled="!whatsapp_data.linkCallButton[1].isEnabled"></Textarea>
                                        <label for="button">Номер телефона</label>
                                    </FloatLabel>
                                    <ToggleSwitch v-model="whatsapp_data.linkCallButton[1].isEnabled" />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div class="format-text">
                            <Fieldset legend="Как форматировать текст">
                            <p class="m-0">
                                Чтобы отформатировать текст в шаблоне, заключите его в специальные символы.<br><br>

                                Полужирное начертание — звездочка (*). Например: «Это очень *важная* информация».<br><br>

                                Курсив — подчеркивание (_). Например: «Добро пожаловать в _Wazzup_!».<br><br>

                                Зачеркивание — тильда (~). Например: «Я и не знал, что это называется ~червячок~ тильда».<br><br>

                                Моноширинный шрифт — три обратных апострофа (```). Например: «```Это предложение набрано моноширинным шрифтом```».
                            </p>
                        </Fieldset>
                        </div>
                    </div>

                    <div class="preview">
                        <p><b>Предпросмотр шаблона</b></p>
                        <Fieldset v-if="whatsapp_data.message.length > 0 || whatsapp_data.showHeader || whatsapp_data.showFooter || whatsapp_data.showButton" class="preview-field" legend="Предварительный просмотр">
                            <p v-if="whatsapp_data.header.length > 0 && whatsapp_data.showHeader" class="message-header">
                                <b>{{ whatsapp_data.header }}<br><br></b>
                            </p>
                            <p class="message-text" v-html="formattedPreview"></p>
                            <p v-if="whatsapp_data.footer.length > 0 && whatsapp_data.showFooter" class="message-footer">
                                {{ whatsapp_data.footer }}
                            </p>
                            <div v-if="whatsapp_data.showButton && whatsapp_data.buttonType.type == 'link_call'">
                                <Divider />
                                <div v-if="whatsapp_data.linkCallButton[0].text.length > 0" class="link-button">
                                    <p class="link-button-text">
                                        <i class="pi pi-external-link"></i>
                                        {{ whatsapp_data.linkCallButton[0].text }}
                                    </p>
                                </div>
                                <div v-if="whatsapp_data.linkCallButton[1].text.length > 0" class="link-button">
                                    <p class="link-button-text">
                                        <i class="pi pi-phone"></i>
                                        {{ whatsapp_data.linkCallButton[1].text }}
                                    </p>
                                </div>
                            </div>
                            
                            <div v-if="whatsapp_data.showButton && whatsapp_data.buttonType.type == 'quick_reply'">
                                <div v-for="button in whatsapp_data.quickReplyButton" :key="button.id" class="message-button">
                                    <p v-if="button.text.length > 0 && button.isEnabled" class="message-button-text">
                                        {{ button.text }}
                                    </p>
                                </div>
                            </div>
                        </Fieldset>
                        <p v-else>Начните заполнять поля, чтобы появился предпросмотр шаблона</p>
                    </div>
                </div>
            </TabPanel>
        </TabPanels>
    </Tabs>
</template>

<script>
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';

import Textarea from 'primevue/textarea';
import FloatLabel from 'primevue/floatlabel';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import Fieldset from 'primevue/fieldset';
import Divider from 'primevue/divider';

import axios from 'axios';
import HeaderPanel from '@/components/HeaderPanel.vue';
import WaEmoji from '@/components/WaEmoji.vue';

export default {
    components: {
        Textarea,
        FloatLabel,
        MultiSelect,
        Checkbox,
        Tabs,
        TabList,
        Tab,
        TabPanels,
        TabPanel,
        Fieldset,
        Divider,
        HeaderPanel,
        WaEmoji
    },
    data() {
        return {
            sms_data: {
                phones: '',
                selectPhones: '',
                selectedPhones: '',
                message: '',
                tinyurl: '0',
                price: [{
                    id: 1,
                    cost: 0,
                    cnt: 0
                }]
            },
            whatsapp_data: {
                showHeader: false,
                header: '',
                showFooter: false,
                footer: '',
                showButton: false,
                quickReplyButton: [
                    { id: 1, text: '', isEnabled: true },
                    { id: 2, text: '', isEnabled: false },
                    { id: 3, text: '', isEnabled: false },
                ],
                linkCallButton: [
                    { id: 1, text: '', link: '', isEnabled: true },
                    { id: 2, text: '', link: '', isEnabled: false },
                ],
                buttonType: {name: 'Быстрый ответ', type: 'quick_reply'},
                buttonTypes: [
                    {name: 'Быстрый ответ', type: 'quick_reply'},
                    {name: 'Ссылка/звонок', type: 'link_call'},
                    ],
                message: ''
            },
            menuItems: [
                {
                    label: 'Форматирование',
                    items: [
                        {
                            label: 'Полужирный',
                            command: () => alert('Полужирный')
                        },
                        {   
                            label: 'Курсив',
                            command: () => alert('Курсив')
                        },
                        {                            
                            label: 'Зачеркнутый',
                            command: () => alert('Зачеркнутый')
                        },
                        {
                            label: 'Моноширинный',
                            command: () => alert('Моноширинный')
                        }
                    ]
                },
                {
                    separator: true
                },
                {
                    label: 'Скопировать',
                    icon: 'pi pi-copy', 
                    command: () => navigator.clipboard.writeText(window.getSelection().toString())
                },
                {
                    label: 'Вставить',
                    icon: 'pi pi-clipboard',
                    command: () => navigator.clipboard.readText().then(text => {
                        document.execCommand('insertText', false, text);
                    })
                },
                {
                    label: 'Вырезать',
                    icon: 'pi pi-wave-pulse',
                    command: () => document.execCommand('cut')
                }
            ]
        }
    },
    mounted: function() {
        this.getPhones();
    },
    methods: {
        async getPhones() {
            const response = await axios.get('https://ncht.bz/api/sms/get-phones');
            const data = await response.data;
            this.selectPhones = data;
        },
        async getSmsPrice() {
            let data;
            const selectedPhones = this.selectPhonesToSrt();
            if (this.sms_data.phones != '' && this.isStrictPhoneList) {
                data = this.sms_data.phones;
                if (selectedPhones != '') {
                    data += ', ' + selectedPhones;
                }
            } else {
                if (selectedPhones != '') {
                    data = selectedPhones;
                }
            }
            const response = await axios.post(`https://ncht.bz/api/sms/get-price`,
                {
                    "phones": data,
                    "message": this.sms_data.message,
                    "tinyurl": this.sms_data.tinyurl
                }
            );
            //const data = await response.data;
            this.price = [await response.data];
        },
        selectPhonesToSrt() {
            if (this.selectedPhones) {
                let data = '';
                this.selectedPhones.forEach((element) => {
                    data += element.phone + ', ';
                })
                data = data.slice(0, -2);
                return data
            } else {
                return ''
            }
        },
        toggleEmojiPicker(event) {
            this.$refs.op.toggle(event);
        },
        onEmojiSelect(event) {
            console.log(event);
            this.whatsapp_data.message += `<span class='emoji' style='background-image: url(https://web.whatsapp.com/emoji/v1/16/0/1/single/w/40/${event});'></span>`;
        },
        replaceEmojiWithWA(text) {
            if (!text) return '';
            return text.replace(/([\uD800-\uDBFF][\uDC00-\uDFFF]|\u203C|\u2049|\u2122|\u2139|\u2194-\u21AA|\u231A-\u231B|\u23E9-\u23F3|\u23F8-\u23FA|\u24C2|\u25AA-\u25FE|\u2600-\u27BF|\u2934-\u2B55|\u3030|\u303D|\u3297|\u3299]|\u263A|\u263B|\u2648-\u2653|\u2660|\u2663|\u2665|\u2666|\u2668|\u267B|\u267E|\u267F|\u2692-\u2697|\u2699|\u269B|\u269C|\u26A0|\u26A1|\u26A7|\u26AA|\u26AB|\u26B0|\u26B1|\u26BD|\u26BE|\u26C4|\u26C5|\u26C8|\u26CF|\u26D1|\u26D3|\u26D4|\u26E9|\u26EA|\u26F0-\u26F5|\u26F7|\u26F8|\u26FA|\u26FD|\u2702|\u2705|\u2708|\u2709|\u270C|\u270D|\u270F|\u2712|\u2714|\u2716|\u271D|\u2721|\u2733|\u2734|\u2744|\u2747|\u2757|\u2763|\u2764|\u27A1|\u2934|\u2935|\u2B00-\u2BFF|\u3030|\u303D|\u3297|\u3299)(\uFE0F)?/g, function (match, emoji, modifier) {
                let code = emoji.codePointAt(0).toString(16);
                // Добавляем ведущие нули до 5 символов
                while (code.length < 6) {
                    code = '0' + code;
                }
                // Добавляем модификатор FE0F, если он присутствует
                const modifierCode = modifier ? '_00fe0f' : '';
                return `<img src="https://web.whatsapp.com/emoji/v1/16/0/1/single/w/40/${code}${modifierCode}.png" width="24" height="24" style="vertical-align: middle;">`;
            });
        },
        formatWhatsAppText(text) {
            if (!text) return '';
            
            let formattedText = text
                // Полужирный текст
                .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
                // Курсив
                .replace(/_(.*?)_/g, '<em>$1</em>')
                // Зачеркнутый текст
                .replace(/~(.*?)~/g, '<del>$1</del>')
                // Моноширинный шрифт
                .replace(/```(.*?)```/g, '<code>$1</code>');
            
            // Заменяем эмодзи на изображения WhatsApp
            formattedText = this.replaceEmojiWithWA(formattedText);
            
            return formattedText;
        },
        onTabsRightClick(event) {
            this.$refs.menu.show(event);
        },
        copyText() {
            alert('Скопировано');
        }
    },
    computed: {
        isStrictPhoneList() {
            return /^\d{11}(, \d{11})*$/.test(this.sms_data.phones);
        },
        formattedPreview() {
            return this.formatWhatsAppText(this.whatsapp_data.message);
        }
    }
}
</script>

<style scoped>
.tabs {
    border: 1px solid #a3a3a3;
    border-radius: 10px;
    padding: 10px;
    min-height: 98vh;
}
.label {
    margin-top: 2rem;
}

label {
    color: black;
}

::v-deep(.p-datatable-table-container) {
    table-layout: auto; /* Таблица подстраивается по содержимому */
    width: 100%; /* Растягиваем таблицу */
    overflow: hidden !important; /* Убираем горизонтальную прокрутку */
    white-space: nowrap;
}

.whatsapp {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.whatsapp-content {
    flex: 1;
    margin-right: 20px;
    max-width: 66%;
}

.whatsapp .p-textarea {
    width: 100%;
}

.message-label {
    margin-bottom: 20px;
    position: relative;
}

.message-label :deep(.p-button) {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
}

#message {
    padding-right: 3ch;
}

.preview {
    margin-top: -1rem;
    width: 30%;
}

.counter {
    font-size: 12px;
    color: #a3a3a3;
    margin-top: 1px;
    text-align: right; /* Выравнивание по правому краю */
}

.footer-container p,
.header-container p,
.button-container p{
    display: flex;
    align-items: center; /* Отцентрирование по вертикали */
}

.message-header {
    margin-top: 0;
    margin-bottom: 0;
}

.message-text {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
}

.message-text img {
    vertical-align: middle;
    margin: 0 2px;
}

.message-text code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 3px;
    font-family: monospace;
}

.message-text strong {
    font-weight: bold;
}

.message-text em {
    font-style: italic;
}

.message-text del {
    text-decoration: line-through;
}

.message-footer {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: #757575;
}

.button-container .p-select{
    margin-bottom: 1rem;
}

.emoji-button {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 5px;
    top: 5px;
}

.float-button {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.float-button .p-floatlabel {
    flex: 1;
    margin-bottom: 0;
}

.float-button .p-toggleswitch {
    margin-top: 1rem;
}

.message-button-text {
    border: 1px solid #a3a3a3;
    border-radius: 4px;
    height: 2rem;
    text-align: center;
    padding-top: 0.5rem;
    color: #1fabe2;
}

.link-button-text {
    height: 2rem;
    text-align: center;
    padding-top: 0.5rem;
    color: #1fabe2;
}

.message-button-text:hover,
.link-button-text:hover {
    cursor: default;
}
</style>