<template>
  <HeaderPanel />
  <div class="container">
    <div id="titleContainer">
      <h1>{{ title }}</h1>
    </div>

    <div id="urlContainer">
      <h2>1. Введите URL адрес вашей страницы</h2>
      <InputGroup class="input-group">
        <ButtonVue @click="switchProtocol()" v-bind:label="selectedProtocol" />
        <AutoComplete v-model="url" dropdown :suggestions="links" @complete="searchUrl" />
        <!--<InputText v-model="url" placeholder="Введите url" />-->
      </InputGroup>
      
    </div>

    <div id="leadContainer">
      <h2>2. К кому относить лиды</h2>
      <SelectButton class="leads" v-model="lead" :options="allLeads" optionLabel="lead" optionValue="lead" />
      <DataTable class="origins" :value="allLeadOrigins" tableStyle="min-width: 25rem" scrollable scrollHeight="15rem"
        @row-click="handleRowClick">
        <ColumnTable field="origin" header="Источники"></ColumnTable>
      </DataTable>
    </div>

    <div id="eventContainer">
      <h2>3. Выберите источник трафика</h2>
      <div class="event-accordion">
        <Accordion :value="[]" multiple>
          <AccordionPanel v-for="{ id, platform} in allOriginPlatforms" :key="id" :value="platform">
                <AccordionHeader>{{ platform }}</AccordionHeader>
                <AccordionContent>
                  <Listbox v-model="event" :options="getEventsByPlatform(platform)" optionLabel="event" class="w-full md:w-56" />
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
      </div>
      <!--<div class="grid-event">
        <div class="grid-container" v-for="uPlatform in unicPlatforms" :key="uPlatform">
          <div class="header">{{ uPlatform }}</div>
          <div class="grid-radio">
            <label v-for="(radio, index) in originType(uPlatform)" :key="index">
              <RadioButton id="radio" v-model="event" inputId="ingredient1" name="option" v-bind:value="radio" />{{
                radio.event }}
            </label><br>
          </div>
        </div>
      </div>-->
    </div>

    <div id="requiredContainer">
      <h2>4. Обязательные параметры</h2>
      <InputGroup class="input-group">
        <ButtonVue class="button" label="utm_source" @click="toggle($event, 'source')"/>
        <PopoverVue ref="sourcePopover">
          <p><b>utm_source</b> – определяет источник трафика, например, название социальной сети, рекламной платформы или сайта, откуда пришел пользователь.</p>
        </PopoverVue>
        <InputText v-model="utm.utm_source" disabled />
      </InputGroup>

      <InputGroup class="input-group">
        <ButtonVue label="utm_medium" @click="toggle($event, 'medium')"/>
        <PopoverVue ref="mediumPopover">
          <p><b>utm_medium</b> – указывает на канал продвижения: это может быть конкретный рекламный аккаунт, подрядчик или сегмент аудитории, через который осуществляется привлечение трафика.</p>
        </PopoverVue>
        <InputText v-model="utm.utm_medium" :disabled="!event || !event.utm_medium?.startsWith('.')" @input="validateCyrillic" @keypress="preventLatinInput" />
      </InputGroup>

      <InputGroup class="input-group">
        <ButtonVue label="utm_campaign" @click="toggle($event, 'campaign')"/>
        <PopoverVue ref="campaignPopover">
          <p><b>utm_campaign</b> – обозначает цель рекламной кампании: название воронки, конкретное мероприятие, акцию или другое маркетинговое событие, связанное с продвижением.</p>
        </PopoverVue>
        <SelectVue v-model="utm.utm_campaign" editable :options="utmCampaign" optionLabel="description" optionValue="name" placeholder="Выберите метку" @input="validateCyrillic" @keypress="preventLatinInput" />
        <!--<InputText v-model="utm.utm_campaign" disabled/>-->
      </InputGroup>
    </div>

    <div id="optionalContainer">
      <h2>5. Необязательные параметры</h2>
      <InputGroup class="input-group">
        <ButtonVue label="utm_content" @click="toggle($event, 'content')"/>
        <PopoverVue ref="contentPopover">
          <p><b>utm_content</b> – помогает различать варианты рекламных материалов в одной кампании: включает название поста, описание креатива, кодовое слово или другую идентификационную метку контента.</p>
        </PopoverVue>
        <AutoComplete v-model="utm.utm_content" dropdown :suggestions="utmContentFiltered" optionLabel="name" @complete="searchUtmContent" @input="validateCyrillic" @keypress="preventLatinInput" />
        <!--<InputText v-model="utm.utm_content" disabled />-->
      </InputGroup>

      <InputGroup class="input-group">
        <ButtonVue label="utm_term" @click="toggle($event, 'term')"/>
        <PopoverVue ref="termPopover">
          <p><b>utm_term</b> – служит для дополнительной детализации, фиксирует дату запуска рекламного объявления для удобного анализа.</p>
        </PopoverVue>
        <!--<InputText v-model="utm.utm_term" disabled />-->
        <DatePicker v-model="utm.utm_term" dateFormat="ddmmyy" @date-select="handleDateSelect" />
      </InputGroup>

      <!--<InputGroup class="input-group">
        <ButtonVue label="utm_id"/>
        <InputText v-model="utm.utm_id" disabled />
      </InputGroup>-->
    </div>

    <div id="resultContainer">
      <h2>6. Скопируйте результат</h2>
      <InputGroup class="input-group">
        <ButtonVue @click="copyText()" label="Скопировать" />
        <InputText v-model="completeUrl" placeholder="Здесь будет ссылка" disabled/>
      </InputGroup>

      <!--<InputGroup class="input-group">
        <ButtonVue @click="shortLink" label="Сократить ссылку" />
        <InputText v-model="short_url" placeholder="Здесь будет ссылка" />
        <InputGroupAddon @click="showLinkShortener = !showLinkShortener">
          <i class="pi pi-cog"></i>
        </InputGroupAddon>
      </InputGroup>-->
    </div>

    <div id="qrContainer">
      <h2>Ваш QR код с UTM-метками</h2>
      <canvas id="qr-code"></canvas>
      <ButtonVue @click="copyCanvasToClipboard()" label="Скопировать" />
    </div>
  </div>
  <LinkShortener v-if="showLinkShortener" @close="handleClose" />
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Listbox from 'primevue/listbox';
import AutoComplete from 'primevue/autocomplete';

import axios from 'axios';
import QRCode from "qrcode";
import HeaderPanel from '@/components/HeaderPanel.vue';
import LinkShortener from '@/components/LinkShortener.vue';
import { useUserStore } from '@/stores/user';

export default {
  components: { 
    LinkShortener,
    HeaderPanel,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    Listbox,
    AutoComplete
   },
  data() {
    return {
      title: 'UTM-конструктор',
      username: this.userStore.user.username,
      selectedProtocol: 'https://',
      links: [],
      url: '',
      utmData: [],
      utmCampaign: [],
      utmContent: [],
      utmContentFiltered: [],
      lead: null,
      origins: null,
      currentOrigin: null,
      utm: {
        utm_source: null,
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: '',
        utm_id: ''
      },
      completeUrl: '',
      short_url: '',
      event: null,
      ingredient: '',
      date: '',
      events: [],
      showLinkShortener: false,
      hoverTimers: {
        source: null,
        medium: null,
        campaign: null,
        content: null,
        term: null
      }
    }
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  mounted: async function () {
    await this.getUtmData();
    await this.getUtmCampaign();
    await this.getUtmContent();
    this.utm.utm_term = this.formatDate(new Date());
    console.log(this.allLeads);
    this.generateQRCode('https://ncht.bz');

  },
  methods: {
    switchProtocol() {
      switch (this.selectedProtocol) {
        case 'http://':
          this.selectedProtocol = 'https://';
          break;
        case 'https://':
          this.selectedProtocol = 'http://';
          break;
      }
    },
    searchUrl(event) {
      let _links = ["https://nechto1.ru"];
      
      this.links = event.query ? _links.filter(item => item.includes(event.query)).map((item) => item) : _links;
    },
    searchUtmContent(event) {
      //this.utmCampaigns = event.query ? this.utmCampaigns.filter(item => item.name.includes(event.query)).map((item) => item) : this.utmCampaigns;
      const query = event.query.toLowerCase();
      this.utmContentFiltered =  event.query ? this.utmContent
        .filter(item => 
          item.name.toLowerCase().includes(query)
        ) : this.utmContent;
    },
    async getUtmData() {
      try {
        const response = await axios.get(`https://ncht.bz/api/utm-data`);
        this.utmData = response.data;
        this.lead = this.utmData[0].lead;
        this.currentOrigin = this.utmData[0].origin;
      } catch (error) {
        console.error(error);
      }
    },
    async getUtmCampaign() {
      try {
        const response = await axios.get('https://ncht.bz/api/utm-campaign');
        this.utmCampaign = response.data;
      } catch (error) {
        console.error('Ошибка при получении меток:', error);
      }
    },
    async getUtmContent() {
      try {
        const response = await axios.get('https://ncht.bz/api/utm-content');
        this.utmContent = response.data;
        this.utmContent.forEach(async (item) => {
          const lastUsedDate = new Date(item.last_used_at);
          const currentDate = new Date();
          const fourteenDaysInMs = 14 * 24 * 60 * 60 * 1000; // 14 дней в миллисекундах
          
          if (lastUsedDate.getTime() + fourteenDaysInMs < currentDate.getTime()) {
            console.log('время вышло');
            const data = {
              id: item.id
            }
            const response = await axios.post('https://ncht.bz/api/delete-utm-content', data);
            const result = await response.data;
            console.log(result);
          } else {
            console.log('время еще не вышло');
          }
        });
      } catch (error) {
        console.error('Ошибка при получении содержимого меток:', error);
      }
    },
    getEventsByPlatform(platform) {
        return this.utmData.filter(item => 
            item.origin === this.currentOrigin && 
            item.platform === platform
        );
    },
    createUtm() {
      if (this.event != null) {
        Object.keys(this.utm).forEach(key => {
          if (this.event[key] && this.event[key].startsWith('.')) {
            this.utm[key] = '';
          } else if (this.event[key]) {
            this.utm[key] = this.event[key];
          }
        });
      }
    },
    transliterate(text) {
        const cyrillicMap = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e',
            'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
            'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
            'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch',
            'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya',
            'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'E',
            'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M',
            'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
            'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sch',
            'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya', ' ': '_'
        };
        
        return text.replace(/[а-яА-ЯёЁ\s]/g, char => cyrillicMap[char] || char);
    },
    resultUrl() {
        let url = '';
        if (this.url == '' || !this.url.includes('.')) {
            url = '';
            return
        } else if (!this.url.includes('https://') && !this.url.includes('http://')) {
            url = this.selectedProtocol + this.url;
        } else {
            url = this.url;
        }

        // Создаем копию utm для обработки
        const processedUtm = { ...this.utm };
        
        // Проверяем каждое значение на наличие кириллицы
        Object.keys(processedUtm).forEach(key => {
            if (processedUtm[key] && /[а-яА-ЯёЁ]/.test(processedUtm[key])) {
                processedUtm[key] = this.transliterate(processedUtm[key]);
            }
        });

        let completeUtm = Object.keys(processedUtm)
            .filter(key => processedUtm[key] !== null && 
                         processedUtm[key] !== undefined && 
                         processedUtm[key] !== "" && 
                         processedUtm[key] !== "none")
            .map(key => `${key}=${processedUtm[key]}`)
            .join('&');

        if (completeUtm) {
            this.completeUrl = `${url}?${completeUtm}`;
        } else {
            this.completeUrl = `${url}`;
        }
        this.generateQRCode(this.completeUrl);
    },
    async shortLink() {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer pmD0cEaG3lskuCXcf7dzkYCBkoP2Dvm4vjyxBmfWBmmQgGgnlDJdf3KHqyrfos74'
        }
      }

      /*let alias = this.generateAlias();

      while (this.checkAlias(alias) == false) {
        alias = this.generateAlias();
      }*/
      
      const data = {
        "url": this.completeUrl,
        "domain_id": 3
      }

      const response = await axios.post('https://url-click.ru/api/v1/links', data, config);
      console.log(response.data.data.short_url);
      this.short_url = response.data.data.short_url;
      //this.$emit('handleShortLink', this.short_url);
    },
    originType(platform) {
      return this.events.filter(item => item.platform === platform);
    },
    generateQRCode(text) {
      const qrCodeDiv = document.getElementById("qr-code");
      qrCodeDiv.innerHTML = ""; // Очищаем предыдущий QR-код

      // Генерируем QR-код
      QRCode.toCanvas(qrCodeDiv, text, { width: 200 }, (error) => {
        if (error) console.error(error);
        this.qrCodeGenerated = true; // Показываем QR-код
      });
    },
    handleClose() {
      this.showLinkShortener = false;
    },
    handleShortLink() {
      this.short_url = this.completeUrl;
    },
    handleRowClick(event) {
      console.log('Выбранная строка:', event.data.origin);
      this.currentOrigin = event.data.origin;
    },
    async copyText() {
      await navigator.clipboard.writeText(this.completeUrl);
      try {
        let date = new Date();
        date.setHours(date.getHours() + 3); // Добавляем 3 часа к UTC
        date.toISOString().slice(0, 19).replace('T', ' ');
        let formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getFullYear()).slice(2)} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

        const data = {
          date: formattedDate,
          username: this.username,
          lead: this.event.lead,
          platform: this.event.platform,
          origin: this.event.origin,
          event: this.event.event,
          utm_campaign: this.utm.utm_campaign,
          link: this.completeUrl,
        }
        const response = await axios.post('https://ncht.bz/api/add_registry', data);
        const result = await response.data;
        console.log(result);
      } catch (error) {
        console.error(error);
      }

      try {
        if (!this.utmContent.includes(this.utm.utm_content) && this.utm.utm_content != '') {
          const data = {
            name: this.utm.utm_content
          }
          const response = await axios.post('https://ncht.bz/api/add-utm-content', data);
          const result = await response.data;
          console.log(result);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async copyCanvasToClipboard() {
      try {
        // Получаем ссылку на canvas
        const canvas = document.getElementById('qr-code');
        if (!canvas) {
          console.error('Элемент canvas не найден.');
          return;
        }

        // Преобразуем содержимое canvas в Data URL (Base64)
        const dataURL = canvas.toDataURL('image/png');

        // Создаем объект Blob из Data URL
        const blob = await (await fetch(dataURL)).blob();

        // Создаем объект ClipboardItem
        const clipboardItem = new ClipboardItem({
          'image/png': blob
        });

        // Копируем в буфер обмена
        await navigator.clipboard.write([clipboardItem]);
      } catch (error) {
        console.error('Ошибка при копировании:', error);
      }
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      return `${String(d.getDate()).padStart(2, '0')}${String(d.getMonth() + 1).padStart(2, '0')}${d.getFullYear()}`;
    },
    handleDateSelect(event) {
      this.utm.utm_term = this.formatDate(event);
    },
    validateCyrillic(event) {
        const value = event.target.value;
        // Проверяем, содержит ли введенный текст только латиницу
        if (/[a-zA-Z]/.test(value)) {
            // Если содержит латиницу, удаляем её
            event.target.value = value.replace(/[a-zA-Z]/g, '');
        }
    },
    preventLatinInput(event) {
        // Разрешаем кириллицу, пробелы, цифры и специальные символы
        if (!/[а-яА-ЯёЁ\s0-9]/.test(event.key)) {
            event.preventDefault();
        }
    },
    toggle(event, type) {
      this.$refs[`${type}Popover`].toggle(event);
      setTimeout(() => {
        this.$refs[`${type}Popover`].toggle(event);
      }, 5000);
    },
    handleMouseEnter(event, type) {
      // Очищаем предыдущий таймер, если он есть
      if (this.hoverTimers[type]) {
        clearTimeout(this.hoverTimers[type]);
      }
      
      // Устанавливаем новый таймер
      this.hoverTimers[type] = setTimeout(() => {
        console.log(type);
        console.log(`${type}Popover`);
        console.log(this.$refs);
        this.$refs[`sourcePopover`].toggle(event);
      }, 1000); // 1 секунда задержки
    },
    handleMouseLeave(type) {
      // Очищаем таймер при уходе мыши
      if (this.hoverTimers[type]) {
        clearTimeout(this.hoverTimers[type]);
        this.hoverTimers[type] = null;
      }
    }
  },
  computed: {
    allLeads() {
      const uniqueLeads = [...new Set(this.utmData.map(item => item.lead))];
      return uniqueLeads.map((lead, index) => ({
        id: index + 1,
        lead: lead
      }));
    },
    allLeadOrigins() {
      const uniqueOrigins = [...new Set(this.utmData.filter(item => item.lead === this.lead).map(item => item.origin))];
      return uniqueOrigins.map((origin, index) => ({
        id: index + 1,
        origin: origin
      }));
    },
    allOriginPlatforms() {
      const platforms = [...new Set(this.utmData.filter(item => item.origin === this.currentOrigin).map(item => item.platform))]
      return platforms.map((platform, index) => ({
        id: index + 1,
        platform: platform
      }));
    },
    allOriginEvents() {
      const events = [...new Set(this.utmData.filter(item => item.origin === this.currentOrigin))];
      return events.map(item => item);
    }
  },
  watch: {
    selectedProtocol() {
      this.resultUrl();
    },
    url() {
      this.resultUrl();
    },
    utm: {
      deep: true,
      handler() {
        this.resultUrl();
      }
    },
    event() {
      this.createUtm();
      this.resultUrl();
    }
  }
}
</script>

<style>
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

#titleContainer,
#urlContainer,
#leadContainer,
#eventContainer {
    grid-column: 1 / -1;
}

#titleContainer,
#urlContainer,
#leadContainer,
#eventContainer,
#requiredContainer,
#optionalContainer,
#resultContainer,
#qrContainer {
    background: #00000090;
    padding: 20px;
    text-align: center;
    border-radius: 2rem;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.08), 0px -3px 15px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
}

.input-group {
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.input-group .p-button {
    width: 140px !important;
    z-index: 15;
}

.input-group input,
.p-autocomplete-input {
    flex: 1 1 auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 0 7px 7px 0;
    margin-left: -1px;
    z-index: 10;
    min-width: 200px;
}

.p-autocomplete-dropdown {
    max-width: 10%;
    width: 2rem !important;
}

.leads {
    margin-bottom: 1rem;
}

.p-datatable,
.p-datatable-table-container {
    border: 1px solid #ccc;
    border-radius: 1rem;
}

.p-row-odd:hover,
.p-row-even:hover {
    cursor: pointer;
}

.p-accordion {
    color: black;
}

#qrContainer button,
#qr-code {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

#qrContainer button {
    margin-top: 1rem;
}

.p-popover {
    max-width: 30%;
}

.p-popover-content p {
    margin: 0;
}

/* Медиа-запросы для мобильных устройств */
/*@media screen and (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
        padding: 10px;
    }

    #requiredContainer,
    #optionalContainer,
    #resultContainer,
    #qrContainer {
        grid-column: 1 / -1;
    }

    .input-group {
        flex-direction: column;
    }

    .input-group .p-button {
        width: 100% !important;
        margin-bottom: 10px;
        border-radius: 7px;
    }

    .input-group input,
    .p-autocomplete-input {
        width: 100%;
        border-radius: 7px;
        margin-left: 0;
    }

    .p-autocomplete-dropdown {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .p-popover {
        max-width: 90%;
    }

    #qrContainer {
        margin-top: 20px;
    }

    #qr-code {
        max-width: 200px;
        width: 100%;
    }
}*/

/* Дополнительные стили для очень маленьких экранов */
/*@media screen and (max-width: 480px) {
    .container {
        padding: 5px;
    }

    #titleContainer,
    #urlContainer,
    #leadContainer,
    #eventContainer,
    #requiredContainer,
    #optionalContainer,
    #resultContainer,
    #qrContainer {
        padding: 15px;
        border-radius: 1rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.2rem;
    }
}*/
</style>