<template>
    <div>
        <span class="pi pi-face-smile" @click="togglePopover($event)"></span>
        <PopoverVue ref="emojiPopover">
            <div class="emoji-container">
                <Tabs :value="emoji[0].group">
                    <TabList>
                        <Tab v-for="group in emoji" :key="group.group" :value="group.group">
                            {{ group.group }}
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel v-for="group in emoji" :key="group.group" :value="group.group">
                            <div class="emoji-list"> 
                                <img v-for="id in group.ids" 
                                    :key="id" 
                                    :src="`https://web.whatsapp.com/emoji/v1/16/0/1/single/w/40/${id}`"
                                    class="emoji"
                                    width="40"
                                    height="40"
                                    @click="selectEmoji(id)"
                                />
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        </PopoverVue>
    </div>
</template>

<script>
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import PopoverVue from 'primevue/popover';

export default {
    components: {
        Tabs,
        TabList,
        Tab,
        TabPanels,
        TabPanel,
        PopoverVue
    },
    data() {
        return {
            emoji: [
                {
                    group: 'Смайлики и люди',
                    ids: [
                        "01f600.png",
                        "01f603.png",
                        "01f604.png",
                        "01f601.png",
                        "01f606.png",
                        "01f979.png",
                        "01f605.png",
                        "01f602.png",
                        "01f923.png",
                        "01f972.png",
                        "00263a_00fe0f.png",
                        "01f60a.png",
                        "01f607.png",
                        "01f642.png",
                        "01f643.png",
                        "01f609.png",
                        "01f60c.png",
                        "01f60d.png",
                        "01f970.png",
                        "01f618.png",
                        "01f617.png",
                        "01f619.png",
                        "01f61a.png",
                        "01f60b.png",
                        "01f61b.png",
                        "01f61d.png",
                        "01f61c.png",
                        "01f92a.png",
                        "01f928.png",
                        "01f9d0.png",
                        "01f913.png",
                        "01f60e.png",
                        "01f978.png",
                        "01f929.png",
                        "01f973.png",
                        "01f642_00200d_002195_00fe0f.png",
                        "01f60f.png",
                        "01f612.png",
                        "01f642_00200d_002194_00fe0f.png",
                        "01f61e.png",
                        "01f614.png",
                        "01f61f.png",
                        "01f615.png",
                        "01f641.png",
                        "002639_00fe0f.png",
                        "01f623.png",
                        "01f616.png",
                        "01f62b.png",
                        "01f629.png",
                        "01f97a.png",
                        "01f622.png",
                        "01f62d.png",
                        "01f624.png",
                        "01f620.png",
                        "01f621.png",
                        "01f92c.png",
                        "01f92f.png",
                        "01f633.png",
                        "01f975.png",
                        "01f976.png",
                        "01f636_00200d_01f32b_00fe0f.png",
                        "01f631.png",
                        "01f628.png",
                        "01f630.png",
                        "01f625.png",
                        "01f613.png",
                        "01f917.png",
                        "01f914.png",
                        "01fae3.png",
                        "01f92d.png",
                        "01fae2.png",
                        "01fae1.png",
                        "01f92b.png",
                        "01fae0.png",
                        "01f925.png",
                        "01f636.png",
                        "01fae5.png",
                        "01f610.png",
                        "01fae4.png",
                        "01f611.png",
                        "01fae8.png",
                        "01f62c.png",
                        "01f644.png",
                        "01f62f.png",
                        "01f626.png",
                        "01f627.png",
                        "01f62e.png",
                        "01f632.png",
                        "01f971.png",
                        "01f634.png",
                        "01f924.png",
                        "01f62a.png",
                        "01f62e_00200d_01f4a8.png",
                        "01f635.png",
                        "01f635_00200d_01f4ab.png",
                        "01f910.png",
                        "01f974.png",
                        "01f922.png",
                        "01f92e.png",
                        "01f927.png",
                        "01f637.png",
                        "01f912.png",
                        "01f915.png",
                        "01f911.png",
                        "01f920.png",
                        "01f608.png",
                        "01f47f.png",
                        "01f479.png",
                        "01f47a.png",
                        "01f921.png",
                        "01f4a9.png",
                        "01f47b.png",
                        "01f480.png",
                        "002620_00fe0f.png",
                        "01f47d.png",
                        "01f47e.png",
                        "01f916.png",
                        "01f383.png",
                        "01f63a.png",
                        "01f638.png",
                        "01f639.png",
                        "01f63b.png",
                        "01f63c.png",
                        "01f63d.png",
                        "01f640.png",
                        "01f63f.png",
                        "01f63e.png",
                        "01faf6.png",
                        "01f932.png",
                        "01f450.png",
                        "01f64c.png",
                        "01f44f.png",
                        "01f91d.png",
                        "01f44d.png",
                        "01f44e.png",
                        "01f44a.png",
                        "00270a.png",
                        "01f91b.png",
                        "01f91c.png",
                        "01faf7.png",
                        "01faf8.png",
                        "01f91e.png",
                        "00270c_00fe0f.png",
                        "01faf0.png",
                        "01f91f.png",
                        "01f918.png",
                        "01f44c.png",
                        "01f90c.png",
                        "01f90f.png",
                        "01faf3.png",
                        "01faf4.png",
                        "01f448.png",
                        "01f449.png",
                        "01f446.png",
                        "01f447.png",
                        "00261d_00fe0f.png",
                        "00270b.png",
                        "01f91a.png",
                        "01f590_00fe0f.png",
                        "01f596.png",
                        "01f44b.png",
                        "01f919.png",
                        "01faf2.png",
                        "01faf1.png",
                        "01f4aa.png",
                        "01f9be.png",
                        "01f595.png",
                        "00270d_00fe0f.png",
                        "01f64f.png",
                        "01faf5.png",
                        "01f9b6.png",
                        "01f9b5.png",
                        "01f9bf.png",
                        "01f484.png",
                        "01f48b.png",
                        "01f444.png",
                        "01fae6.png",
                        "01f9b7.png",
                        "01f445.png",
                        "01f442.png",
                        "01f9bb.png",
                        "01f443.png",
                        "01f463.png",
                        "01f441_00fe0f.png",
                        "01f440.png",
                        "01fac0.png",
                        "01fac1.png",
                        "01f9e0.png",
                        "01f5e3_00fe0f.png",
                        "01f464.png",
                        "01f465.png",
                        "01fac2.png",
                        "01f476.png",
                        "01f467.png",
                        "01f9d2.png",
                        "01f466.png",
                        "01f469.png",
                        "01f9d1.png",
                        "01f468.png",
                        "01f469_00200d_01f9b1.png",
                        "01f9d1_00200d_01f9b1.png",
                        "01f468_00200d_01f9b1.png",
                        "01f469_00200d_01f9b0.png",
                        "01f9d1_00200d_01f9b0.png",
                        "01f468_00200d_01f9b0.png",
                        "01f471_00200d_002640_00fe0f.png",
                        "01f471.png",
                        "01f471_00200d_002642_00fe0f.png",
                        "01f469_00200d_01f9b3.png",
                        "01f9d1_00200d_01f9b3.png",
                        "01f468_00200d_01f9b3.png",
                        "01f469_00200d_01f9b2.png",
                        "01f9d1_00200d_01f9b2.png",
                        "01f468_00200d_01f9b2.png",
                        "01f9d4_00200d_002640_00fe0f.png",
                        "01f9d4.png",
                        "01f9d4_00200d_002642_00fe0f.png",
                        "01f475.png",
                        "01f9d3.png",
                        "01f474.png",
                        "01f472.png",
                        "01f473_00200d_002640_00fe0f.png",
                        "01f473.png",
                        "01f473_00200d_002642_00fe0f.png",
                        "01f9d5.png",
                        "01f46e_00200d_002640_00fe0f.png",
                        "01f46e.png",
                        "01f46e_00200d_002642_00fe0f.png",
                        "01f477_00200d_002640_00fe0f.png",
                        "01f477.png",
                        "01f477_00200d_002642_00fe0f.png",
                        "01f482_00200d_002640_00fe0f.png",
                        "01f482.png",
                        "01f482_00200d_002642_00fe0f.png",
                        "01f575_00fe0f_00200d_002640_00fe0f.png",
                        "01f575_00fe0f.png",
                        "01f575_00fe0f_00200d_002642_00fe0f.png",
                        "01f469_00200d_002695_00fe0f.png",
                        "01f9d1_00200d_002695_00fe0f.png",
                        "01f468_00200d_002695_00fe0f.png",
                        "01f469_00200d_01f33e.png",
                        "01f9d1_00200d_01f33e.png",
                        "01f468_00200d_01f33e.png",
                        "01f469_00200d_01f373.png",
                        "01f9d1_00200d_01f373.png",
                        "01f468_00200d_01f373.png",
                        "01f469_00200d_01f393.png",
                        "01f9d1_00200d_01f393.png",
                        "01f468_00200d_01f393.png",
                        "01f469_00200d_01f3a4.png",
                        "01f9d1_00200d_01f3a4.png",
                        "01f468_00200d_01f3a4.png",
                        "01f469_00200d_01f3eb.png",
                        "01f9d1_00200d_01f3eb.png",
                        "01f468_00200d_01f3eb.png",
                        "01f469_00200d_01f3ed.png",
                        "01f9d1_00200d_01f3ed.png",
                        "01f468_00200d_01f3ed.png",
                        "01f469_00200d_01f4bb.png",
                        "01f9d1_00200d_01f4bb.png",
                        "01f468_00200d_01f4bb.png",
                        "01f469_00200d_01f4bc.png",
                        "01f9d1_00200d_01f4bc.png",
                        "01f468_00200d_01f4bc.png",
                        "01f469_00200d_01f527.png",
                        "01f9d1_00200d_01f527.png",
                        "01f468_00200d_01f527.png",
                        "01f469_00200d_01f52c.png",
                        "01f9d1_00200d_01f52c.png",
                        "01f468_00200d_01f52c.png",
                        "01f469_00200d_01f3a8.png",
                        "01f9d1_00200d_01f3a8.png",
                        "01f468_00200d_01f3a8.png",
                        "01f469_00200d_01f692.png",
                        "01f9d1_00200d_01f692.png",
                        "01f468_00200d_01f692.png",
                        "01f469_00200d_002708_00fe0f.png",
                        "01f9d1_00200d_002708_00fe0f.png",
                        "01f468_00200d_002708_00fe0f.png",
                        "01f469_00200d_01f680.png",
                        "01f9d1_00200d_01f680.png",
                        "01f468_00200d_01f680.png",
                        "01f469_00200d_002696_00fe0f.png",
                        "01f9d1_00200d_002696_00fe0f.png",
                        "01f468_00200d_002696_00fe0f.png",
                        "01f470_00200d_002640_00fe0f.png",
                        "01f470.png",
                        "01f470_00200d_002642_00fe0f.png",
                        "01f935_00200d_002640_00fe0f.png",
                        "01f935.png",
                        "01f935_00200d_002642_00fe0f.png",
                        "01f478.png",
                        "01fac5.png",
                        "01f934.png",
                        "01f977.png",
                        "01f9b8_00200d_002640_00fe0f.png",
                        "01f9b8.png",
                        "01f9b8_00200d_002642_00fe0f.png",
                        "01f9b9_00200d_002640_00fe0f.png",
                        "01f9b9.png",
                        "01f9b9_00200d_002642_00fe0f.png",
                        "01f936.png",
                        "01f9d1_00200d_01f384.png",
                        "01f385.png",
                        "01f9d9_00200d_002640_00fe0f.png",
                        "01f9d9.png",
                        "01f9d9_00200d_002642_00fe0f.png",
                        "01f9dd_00200d_002640_00fe0f.png",
                        "01f9dd.png",
                        "01f9dd_00200d_002642_00fe0f.png",
                        "01f9cc.png",
                        "01f9db_00200d_002640_00fe0f.png",
                        "01f9db.png",
                        "01f9db_00200d_002642_00fe0f.png",
                        "01f9df_00200d_002640_00fe0f.png",
                        "01f9df.png",
                        "01f9df_00200d_002642_00fe0f.png",
                        "01f9de_00200d_002640_00fe0f.png",
                        "01f9de.png",
                        "01f9de_00200d_002642_00fe0f.png",
                        "01f9dc_00200d_002640_00fe0f.png",
                        "01f9dc.png",
                        "01f9dc_00200d_002642_00fe0f.png",
                        "01f9da_00200d_002640_00fe0f.png",
                        "01f9da.png",
                        "01f9da_00200d_002642_00fe0f.png",
                        "01f47c.png",
                        "01f930.png",
                        "01fac4.png",
                        "01fac3.png",
                        "01f931.png",
                        "01f469_00200d_01f37c.png",
                        "01f9d1_00200d_01f37c.png",
                        "01f468_00200d_01f37c.png",
                        "01f647_00200d_002640_00fe0f.png",
                        "01f647.png",
                        "01f647_00200d_002642_00fe0f.png",
                        "01f481_00200d_002640_00fe0f.png",
                        "01f481.png",
                        "01f481_00200d_002642_00fe0f.png",
                        "01f645_00200d_002640_00fe0f.png",
                        "01f645.png",
                        "01f645_00200d_002642_00fe0f.png",
                        "01f646_00200d_002640_00fe0f.png",
                        "01f646.png",
                        "01f646_00200d_002642_00fe0f.png",
                        "01f64b_00200d_002640_00fe0f.png",
                        "01f64b.png",
                        "01f64b_00200d_002642_00fe0f.png",
                        "01f9cf_00200d_002640_00fe0f.png",
                        "01f9cf.png",
                        "01f9cf_00200d_002642_00fe0f.png",
                        "01f926_00200d_002640_00fe0f.png",
                        "01f926.png",
                        "01f926_00200d_002642_00fe0f.png",
                        "01f937_00200d_002640_00fe0f.png",
                        "01f937.png",
                        "01f937_00200d_002642_00fe0f.png",
                        "01f64e_00200d_002640_00fe0f.png",
                        "01f64e.png",
                        "01f64e_00200d_002642_00fe0f.png",
                        "01f64d_00200d_002640_00fe0f.png",
                        "01f64d.png",
                        "01f64d_00200d_002642_00fe0f.png",
                        "01f487_00200d_002640_00fe0f.png",
                        "01f487.png",
                        "01f487_00200d_002642_00fe0f.png",
                        "01f486_00200d_002640_00fe0f.png",
                        "01f486.png",
                        "01f486_00200d_002642_00fe0f.png",
                        "01f9d6_00200d_002640_00fe0f.png",
                        "01f9d6.png",
                        "01f9d6_00200d_002642_00fe0f.png",
                        "01f485.png",
                        "01f933.png",
                        "01f483.png",
                        "01f57a.png",
                        "01f46f_00200d_002640_00fe0f.png",
                        "01f46f.png",
                        "01f46f_00200d_002642_00fe0f.png",
                        "01f574_00fe0f.png",
                        "01f469_00200d_01f9bd.png",
                        "01f9d1_00200d_01f9bd.png",
                        "01f468_00200d_01f9bd.png",
                        "01f469_00200d_01f9bc.png",
                        "01f9d1_00200d_01f9bc.png",
                        "01f468_00200d_01f9bc.png",
                        "01f6b6_00200d_002640_00fe0f.png",
                        "01f6b6.png",
                        "01f6b6_00200d_002642_00fe0f.png",
                        "01f469_00200d_01f9af.png",
                        "01f9d1_00200d_01f9af.png",
                        "01f468_00200d_01f9af.png",
                        "01f9ce_00200d_002640_00fe0f.png",
                        "01f9ce.png",
                        "01f9ce_00200d_002642_00fe0f.png",
                        "01f3c3_00200d_002640_00fe0f.png",
                        "01f3c3.png",
                        "01f3c3_00200d_002642_00fe0f.png",
                        "01f9cd_00200d_002640_00fe0f.png",
                        "01f9cd.png",
                        "01f9cd_00200d_002642_00fe0f.png",
                        "01f46b.png",
                        "01f46d.png",
                        "01f46c.png",
                        "01f469_00200d_002764_00fe0f_00200d_01f468.png",
                        "01f469_00200d_002764_00fe0f_00200d_01f469.png",
                        "01f491.png",
                        "01f468_00200d_002764_00fe0f_00200d_01f468.png",
                        "01f469_00200d_002764_00fe0f_00200d_01f48b_00200d_01f468.png",
                        "01f469_00200d_002764_00fe0f_00200d_01f48b_00200d_01f469.png",
                        "01f48f.png",
                        "01f468_00200d_002764_00fe0f_00200d_01f48b_00200d_01f468.png",
                        "01faa2.png",
                        "01f9f6.png",
                        "01f9f5.png",
                        "01faa1.png",
                        "01f9e5.png",
                        "01f97c.png",
                        "01f9ba.png",
                        "01f45a.png",
                        "01f455.png",
                        "01f456.png",
                        "01fa72.png",
                        "01fa73.png",
                        "01f454.png",
                        "01f457.png",
                        "01f459.png",
                        "01fa71.png",
                        "01f458.png",
                        "01f97b.png",
                        "01fa74.png",
                        "01f97f.png",
                        "01f460.png",
                        "01f461.png",
                        "01f462.png",
                        "01f45e.png",
                        "01f45f.png",
                        "01f97e.png",
                        "01f9e6.png",
                        "01f9e4.png",
                        "01f9e3.png",
                        "01f3a9.png",
                        "01f9e2.png",
                        "01f452.png",
                        "01f393.png",
                        "0026d1_00fe0f.png",
                        "01fa96.png",
                        "01f451.png",
                        "01f48d.png",
                        "01f45d.png",
                        "01f45b.png",
                        "01f45c.png",
                        "01f4bc.png",
                        "01f392.png",
                        "01f9f3.png",
                        "01f453.png",
                        "01f576_00fe0f.png",
                        "01f97d.png",
                        "01f302.png"
                    ]
                },
                {
                    group: 'Животные и природа',
                    ids: [
                        "01f436.png",
                        "01f431.png",
                        "01f42d.png",
                        "01f439.png",
                        "01f430.png",
                        "01f98a.png",
                        "01f43b.png",
                        "01f43c.png",
                        "01f43b_00200d_002744_00fe0f.png",
                        "01f428.png",
                        "01f42f.png",
                        "01f981.png",
                        "01f42e.png",
                        "01f437.png",
                        "01f43d.png",
                        "01f438.png",
                        "01f435.png",
                        "01f648.png",
                        "01f649.png",
                        "01f64a.png",
                        "01f412.png",
                        "01f414.png",
                        "01f427.png",
                        "01f426.png",
                        "01f424.png",
                        "01f423.png",
                        "01f425.png",
                        "01fabf.png",
                        "01f986.png",
                        "01f426_00200d_002b1b.png",
                        "01f985.png",
                        "01f989.png",
                        "01f987.png",
                        "01f43a.png",
                        "01f417.png",
                        "01f434.png",
                        "01f984.png",
                        "01face.png",
                        "01f41d.png",
                        "01fab1.png",
                        "01f41b.png",
                        "01f98b.png",
                        "01f40c.png",
                        "01f41e.png",
                        "01f41c.png",
                        "01fab0.png",
                        "01fab2.png",
                        "01fab3.png",
                        "01f99f.png",
                        "01f997.png",
                        "01f577_00fe0f.png",
                        "01f578_00fe0f.png",
                        "01f982.png",
                        "01f422.png",
                        "01f40d.png",
                        "01f98e.png",
                        "01f996.png",
                        "01f995.png",
                        "01f419.png",
                        "01f991.png",
                        "01fabc.png",
                        "01f990.png",
                        "01f99e.png",
                        "01f980.png",
                        "01f421.png",
                        "01f420.png",
                        "01f41f.png",
                        "01f42c.png",
                        "01f433.png",
                        "01f40b.png",
                        "01f988.png",
                        "01f9ad.png",
                        "01f40a.png",
                        "01f405.png",
                        "01f406.png",
                        "01f993.png",
                        "01f98d.png",
                        "01f9a7.png",
                        "01f9a3.png",
                        "01f418.png",
                        "01f99b.png",
                        "01f98f.png",
                        "01f42a.png",
                        "01f42b.png",
                        "01f992.png",
                        "01f998.png",
                        "01f9ac.png",
                        "01f403.png",
                        "01f402.png",
                        "01f404.png",
                        "01facf.png",
                        "01f40e.png",
                        "01f416.png",
                        "01f40f.png",
                        "01f411.png",
                        "01f999.png",
                        "01f410.png",
                        "01f98c.png",
                        "01f415.png",
                        "01f429.png",
                        "01f9ae.png",
                        "01f415_00200d_01f9ba.png",
                        "01f408.png",
                        "01f408_00200d_002b1b.png",
                        "01fab6.png",
                        "01fabd.png",
                        "01f413.png",
                        "01f983.png",
                        "01f9a4.png",
                        "01f99a.png",
                        "01f99c.png",
                        "01f9a2.png",
                        "01f9a9.png",
                        "01f54a_00fe0f.png",
                        "01f407.png",
                        "01f99d.png",
                        "01f9a8.png",
                        "01f9a1.png",
                        "01f9ab.png",
                        "01f9a6.png",
                        "01f9a5.png",
                        "01f401.png",
                        "01f400.png",
                        "01f43f_00fe0f.png",
                        "01f994.png",
                        "01f43e.png",
                        "01f409.png",
                        "01f432.png",
                        "01f426_00200d_01f525.png",
                        "01f335.png",
                        "01f384.png",
                        "01f332.png",
                        "01f333.png",
                        "01f334.png",
                        "01fab5.png",
                        "01f331.png",
                        "01f33f.png",
                        "002618_00fe0f.png",
                        "01f340.png",
                        "01f38d.png",
                        "01fab4.png",
                        "01f38b.png",
                        "01f343.png",
                        "01f342.png",
                        "01f341.png",
                        "01faba.png",
                        "01fab9.png",
                        "01f344.png",
                        "01f344_00200d_01f7eb.png",
                        "01f41a.png",
                        "01fab8.png",
                        "01faa8.png",
                        "01f33e.png",
                        "01f490.png",
                        "01f337.png",
                        "01f339.png",
                        "01f940.png",
                        "01fabb.png",
                        "01fab7.png",
                        "01f33a.png",
                        "01f338.png",
                        "01f33c.png",
                        "01f33b.png",
                        "01f31e.png",
                        "01f31d.png",
                        "01f31b.png",
                        "01f31c.png",
                        "01f31a.png",
                        "01f315.png",
                        "01f316.png",
                        "01f317.png",
                        "01f318.png",
                        "01f311.png",
                        "01f312.png",
                        "01f313.png",
                        "01f314.png",
                        "01f319.png",
                        "01f30e.png",
                        "01f30d.png",
                        "01f30f.png",
                        "01fa90.png",
                        "01f4ab.png",
                        "002b50.png",
                        "01f31f.png",
                        "002728.png",
                        "0026a1.png",
                        "002604_00fe0f.png",
                        "01f4a5.png",
                        "01f525.png",
                        "01f32a_00fe0f.png",
                        "01f308.png",
                        "002600_00fe0f.png",
                        "01f324_00fe0f.png",
                        "0026c5.png",
                        "01f325_00fe0f.png",
                        "002601_00fe0f.png",
                        "01f326_00fe0f.png",
                        "01f327_00fe0f.png",
                        "0026c8_00fe0f.png",
                        "01f329_00fe0f.png",
                        "01f328_00fe0f.png",
                        "002744_00fe0f.png",
                        "002603_00fe0f.png",
                        "0026c4.png",
                        "01f32c_00fe0f.png",
                        "01f4a8.png",
                        "01f4a7.png",
                        "01f4a6.png",
                        "01fae7.png",
                        "002614.png",
                        "002602_00fe0f.png",
                        "01f30a.png",
                        "01f32b_00fe0f.png"
                    ]
                },
                {
                    group: 'Еда и напитки',
                    ids: [
                        "01f354.png",
                        "01f355.png"
                    ]
                },
                {
                    group: 'Спорт и фитнес',
                    ids: [
                        "01f3c4.png",
                        "01f3c5.png"
                    ]
                },
                {
                    group: 'Путешествия и места',
                    ids: [
                        "01f4a2.png",
                        "01f4a3.png"
                    ]
                },
                {
                    group: 'Объекты',
                    ids: [
                        "01f4a2.png",
                        "01f4a3.png"
                    ]
                },
                {
                    group: 'Символы',
                    ids: [
                        "01f4a2.png",
                        "01f4a3.png"
                    ]
                },
                {
                    group: 'Флаги',
                    ids: [
                        "01f4a2.png",
                        "01f4a3.png"
                    ]
                }
            ]
        }
    },
    methods: {
        togglePopover(event) {
            this.$refs.emojiPopover.toggle(event);
        },
        selectEmoji(id) {
            this.$emit('select', id);
            this.$refs.emojiPopover.hide();
        }
    }
}
</script>

<style scoped>
.emoji-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
}

.emoji-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    flex: 1;
    gap: 5px;
    max-height: 430px;
}

.emoji {
    cursor: pointer;
    transition: transform 0.2s;
}

.emoji:hover {
    transform: scale(1.1);
}

/* Стили для табов */
::v-deep(.p-tabs) {
    display: flex;
    flex-direction: column;
}

::v-deep(.p-tabs-nav) {
    margin-bottom: 10px;
}

::v-deep(.p-tabpanel) {
    flex: 1;
    overflow: hidden;
}

:deep(.p-popover) {
    width: 400px;
    height: 500px;
    max-width: none;
}
::v-deep(.p-popover .p-popover-content) {
    padding: 0;
    height: 100%;
}

.p-tablist {
    overflow-x: auto;
}

.pi-face-smile {
    cursor: pointer;
    font-size: 1.5rem;
}
</style>
